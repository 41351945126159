
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Admin.vue'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const refProp = {
			query: '',
			showHidden: false,
		}
		const modelRef = ref(refProp)
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			totalLoading: true,
			loading: false,
			certainMode: false,
			changeLoading: false,
			applys: [],
			interviews: [],
			errorDialog,
			model: modelRef,
		}
	},
	mounted: function () {
		document.title = '応募一覧 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const obj: any = location.search
					.slice(1)
					.split('&')
					.map((p) => p.split('='))
					.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
				const target = obj.certain
				this.certainMode = !!target
				this.loading = true
				let endpoint = `/v1/admin/apply/not_approved`
				if (target) endpoint = `/v1/apply/${target}`
				const dataR = await api.get(endpoint)
				const data2 = await api.get('/v1/admin/interview/not_approved')
				const data = target ? [dataR] : dataR
				this.totalLoading = false
				this.loading = false
				this.applys = data
				this.interviews = data2
			} catch (e: any) {
				this.loading = false
				this.totalLoading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		getDate: function (unix: number) {
			return moment(new Date(unix * 1000)).format('YYYY年MM月DD日')
		},
		goEdit: function (id: string) {
			window.open(`/hospital/apply_edit/${id}`, '_blank')
		},
		goCheck: function (id: string) {
			window.open(`/apply/${id}`, '_blank')
		},
		changeStatus: async function (id: string, status: string) {
			try {
				this.changeLoading = true
				const dataR = await api.post(`/v1/admin/apply/status_change`, {id, status})
				this.changeLoading = false
				this.init()
			} catch (e: any) {
				this.changeLoading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		goIntEdit: function (id: string) {
			window.open(`/hospital/interview_edit/${id}`, '_blank')
		},
		goIntCheck: function (hospital: string, id: string) {
			window.open(`/h/${hospital}#interview_${id}`, '_blank')
		},
		changeIntStatus: async function (id: string, status: string) {
			try {
				this.changeLoading = true
				const dataR = await api.post(`/v1/admin/interview/status_change`, {id, status})
				this.changeLoading = false
				this.init()
			} catch (e: any) {
				this.changeLoading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		label: function (label: string) {
			if (label == 'draft') return '下書き'
			if (label == 'waitReview') return '審査待ち'
			if (label == 'public') return '公開中'
			if (label == 'finished') return '終了済み'
			if (label == 'accepted') return '公開中'
		},
		labelColor: function (label: string) {
			if (label == 'draft') return 'gray'
			if (label == 'waitReview') return 'orange'
			if (label == 'public') return 'green'
			if (label == 'finished') return 'blue'
			if (label == 'accepted') return 'green'
		},
		unCertain: function () {
			location.href = './apply'
		},
	},
})
